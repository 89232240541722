<template>
  <div>

    <div class="wrapper wrapper-full-page">
      <loading-panel></loading-panel>
      <div class="full-page">
        <zoom-center-transition
          :duration="200"
          mode="out-in"
        >
          <div
            class="content"
            v-show="show"
          >

            <div class="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 ml-auto mr-auto mt-5">
              <div class="row">
                <div class="col-8 mx-auto">
                  <img
                    :src="require('@/assets/img/logo/logo_goso.png')"
                    alt="Logo Goso"
                    class="img-fluid mb-5"
                  />
                </div>
              </div>

              <router-view></router-view>

              <!-- <div class="row">
                <div class="col-3 mx-auto">
                  <img
                    :src="require('@/assets/img/logo/logo_ep.png')"
                    alt="Logo European Parliament"
                    class="img-fluid mt-5 mb-4"
                  />
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-3 mx-auto d-flex justify-content-center">
                  <span class="glow">Version 2.0 (sandbox)</span>
                </div>
              </div> -->

            </div>
          </div>
        </zoom-center-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { ZoomCenterTransition } from 'vue2-transitions';

export default {
  components: {
    ZoomCenterTransition,
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.show = !this.show;
  },
};
</script>
<style scoped>
.glow {
  font-size: 18px;
  color: #343A40;
  text-align: center;
  /* text-shadow: 0 0 3px #fff, 0 0 5px #fff, 0 0 8px white, 0 0 10px white, 0 0 12px #fff, 0 0 15px #fff, 0 0 18px #fff; */
}
</style>
