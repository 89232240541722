const state = {
  open: true,
  filterSidebar: true,
};

const getters = {
  sidebar: (state) => {
    return state.open;
  },
  filterSidebar: (state) => {
    return state.filterSidebar;
  },
};

const mutations = {
  sidebar: (state, to) => {
    state.open = to;
  },
  filterSidebar: (state, to) => {
    state.filterSidebar = to;
  },
};

export default {
  state,
  getters,
  mutations,
};
