import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import reports from './modules/reports.js';
import loader from './modules/loader.js';
import login from './modules/login.js';
import token from './modules/token.js';
import section from './modules/section.js';
import project from './modules/project.js';
import category from './modules/category.js';
import comments from './modules/comments.js';
import sidebar from './modules/sidebar.js';
import card from './modules/card.js';
import stats from './modules/stats.js';
import filters from './modules/filters.js';
import explore from './modules/explore.js';
import selection from './modules/selection.js';
import lastReports from './modules/lastReports.js';
import alert from './modules/alert.js';
import wi from './modules/wi.js';

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    reports,
    loader,
    login,
    token,
    section,
    project,
    category,
    comments,
    sidebar,
    card,
    stats,
    filters,
    explore,
    selection,
    lastReports,
    alert,
    wi,
  },
  plugins: [createPersistedState()],
});
