var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed-top"},[_c('b-navbar',{staticClass:"navbar-shadow",attrs:{"id":"navbar","toggleable":"md","type":"light","variant":"light","sticky":""}},[_c('b-img',{staticClass:"logo ml-2 ml-md-4 d-block d-sm-none",attrs:{"src":require('@/assets/img/logo/logo_goso_identity_dark_84x84.png'),"alt":"Logo"}}),_c('b-img',{staticClass:"logo d-none d-sm-block",attrs:{"src":require('@/assets/img/logo/logo_goso_identity_light_84x84.png'),"alt":"Logo"}}),_c('b-dropdown',{staticClass:"ml-2 ml-md-5",attrs:{"id":"dropdown-1","variant":"none"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"navbar-site-button"},[_vm._v(_vm._s(_vm.currentProjectRef))]),_vm._v(" "),_c('i',{staticClass:"el-icon-sort"})]},proxy:true}])},[_c('b-dropdown-header',{attrs:{"id":"dropdown-header-label"}},[_vm._v(" "+_vm._s(_vm.$t('navbar.myProjects'))+" ")]),_vm._l((_vm.projects),function(project){return _c('b-dropdown-item',{key:project.ref,attrs:{"value":project.ref},on:{"click":function($event){$event.preventDefault();return _vm.changeProject(project)}}},[[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(project.ref))]),_c('br'),_c('span',{staticClass:"text-info font-weight-light"},[_vm._v(_vm._s(project.projectShortname))])]],2)})],2),_c('div',{staticClass:"ml-auto"},[_c('b-nav-item',{staticClass:"d-inline-block d-md-none",attrs:{"to":{path: '/scan'}}},[_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":['fal', 'qrcode'
          ]}})],1),_c('b-nav-item',{staticClass:"d-inline-block d-md-none",attrs:{"to":"/search/mobile"},on:{"click":_vm.search}},[_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":['fal', 'search'
          ]}})],1),_c('b-nav-item',{staticClass:"d-inline-block d-md-none",attrs:{"to":this.$store.getters.lastSubsectionRouteInSection.notifications}},[(_vm.notifications)?_c('el-badge',{staticClass:"item",attrs:{"is-dot":""}},[_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":['fal', 'bell'
          ]}})],1):_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":['fal', 'bell'
          ]}})],1)],1),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{staticClass:"d-none d-md-block ml-3",attrs:{"to":{path: '/scan'}}},[_c('font-awesome-icon',{staticClass:"fa-lg d-xl-none",attrs:{"icon":['fal', 'qrcode'
          ]}}),_c('span',{staticClass:"d-none d-xl-inline-block ml-3"},[_vm._v("Scan")])],1),_c('b-nav-item',{staticClass:"d-none d-md-block ml-3",attrs:{"to":this.$store.getters.lastSubsectionRouteInSection.explore},on:{"click":_vm.explore}},[_c('font-awesome-icon',{staticClass:"fa-lg d-xl-none",attrs:{"icon":['fal', 'search'
          ]}}),_c('span',{staticClass:"d-none d-xl-inline-block ml-3"},[_vm._v(_vm._s(_vm.$t('navbar.nav.explore')))])],1),_c('b-nav-item',{staticClass:"ml-3 ml-md-2",attrs:{"to":'/stats'}},[_c('font-awesome-icon',{staticClass:"fa-lg d-none d-md-block d-xl-none",attrs:{"icon":['fal', 'chart-line'
          ]}}),_c('span',{staticClass:"d-none d-xl-inline-block ml-3"},[_vm._v(_vm._s(_vm.$t('navbar.stats')))])],1),_c('b-nav-item',{staticClass:"d-none d-md-block ml-3",attrs:{"to":this.$store.getters.lastSubsectionRouteInSection.notifications}},[(_vm.notifications)?_c('el-badge',{staticClass:"item d-xl-none",attrs:{"is-dot":""}},[_c('font-awesome-icon',{staticClass:"fa-lg d-xl-none",attrs:{"icon":['fal', 'bell'
          ]}})],1):_c('font-awesome-icon',{staticClass:"fa-lg d-xl-none",attrs:{"icon":['fal', 'bell'
          ]}}),(_vm.notifications)?_c('el-badge',{attrs:{"is-dot":""}},[_c('span',{staticClass:"d-none d-xl-inline-block ml-3"},[_vm._v(_vm._s(_vm.$t('navbar.notifications')))])]):_c('span',{staticClass:"d-none d-xl-inline-block ml-3"},[_vm._v(_vm._s(_vm.$t('navbar.notifications')))])],1),(_vm.admin)?_c('div',[_c('b-nav-item',{staticClass:"ml-3 ml-md-2",attrs:{"to":this.$store.getters.lastSubsectionRouteInSection.admin}},[(!_vm.isTablet)?_c('span',{staticClass:"d-sm-inline-block d-lg-none d-xl-inline-block ml-2"},[_vm._v(_vm._s(_vm.$t('navbar.admin')))]):_vm._e(),_c('font-awesome-icon',{staticClass:"fa-lg d-none d-md-inline-block d-lg-inline-block d-xl-none",attrs:{"icon":['fal', 'hammer']}})],1)],1):_vm._e(),_c('b-nav-item-dropdown',{staticClass:"ml-4",attrs:{"id":"dropdown-lang","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.lang)+" ")]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setLocale('fr')}}},[_c('img',{attrs:{"src":require('@/assets/img/flag/flag_fr.svg'),"alt":"French flag"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('navbar.french')))])]),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setLocale('en')}}},[_c('img',{attrs:{"src":require('@/assets/img/flag/flag_en.svg'),"alt":"English flag"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('navbar.english')))])])],1),_c('b-nav-item-dropdown',{staticClass:"ml-3 ml-md-2 border-none",attrs:{"right":"","id":"dropdown-account"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"d-none d-md-inline-block ml-4"},[_vm._v(" "+_vm._s(_vm.userInitials)+" "),_c('font-awesome-icon',{staticClass:"fa-xs",attrs:{"icon":['fas', 'caret-down'
          ]}})],1),_c('span',{staticClass:"d-inline-block d-md-none ml-2"},[_vm._v(_vm._s(_vm.$t('navbar.myAccount')))])]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"href":"#","to":{ name: 'home' }}},[_c('span',{staticClass:"dropdown-item-account"},[_vm._v(_vm._s(_vm.$t('navbar.home')))])]),_c('b-dropdown-divider',{staticClass:"d-none d-md-block"}),_c('b-dropdown-item',{attrs:{"href":"\n            #","to":{ name: 'settings' }}},[_c('span',{staticClass:"dropdown-item-account"},[_vm._v(_vm._s(_vm.$t('navbar.settings')))])]),_c('b-dropdown-divider',{staticClass:"d-none d-md-block"}),_c('b-dropdown-item',{attrs:{"href":"#","to":{ name: 'helphome' }}},[_c('span',{staticClass:"dropdown-item-account"},[_vm._v(_vm._s(_vm.$t('navbar.support')))])]),_c('b-dropdown-divider',{staticClass:"d-none d-md-block"}),_c('b-dropdown-item',{staticClass:"dropdown-item-logout",attrs:{"href":"#","to":{ name: 'logout' }}},[_c('el-button',{attrs:{"type":"warning","size":"small"}},[_vm._v(_vm._s(_vm.$t('navbar.logout')))])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }