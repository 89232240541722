const state = {
    accessToken: ''
}

const getters = {
    token: state => {
        return state.accessToken
      }
}

const mutations = {
    setAccessToken (state, token) {
        state.accessToken = token
      }
}

export default {
    state,
    getters,
    mutations,
}