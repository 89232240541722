<template>
  <div class="fixed-top">

    <b-navbar
      id="navbar"
      toggleable="md"
      type="light"
      variant="light"
      sticky
      class="navbar-shadow"
    >
      <!-- LOGO -->
      <b-img
        :src="require('@/assets/img/logo/logo_goso_identity_dark_84x84.png')"
        alt="Logo"
        class="logo ml-2 ml-md-4 d-block d-sm-none"
      >
      </b-img>
      <b-img
        :src="require('@/assets/img/logo/logo_goso_identity_light_84x84.png')"
        alt="Logo"
        class="logo d-none d-sm-block"
      ></b-img>
      <!-- <div class="breakpoint"></div> -->
      <!-- PROJECT DROPDOWN -->
      <b-dropdown
        id="dropdown-1"
        variant="none"
        class="ml-2 ml-md-5"
      >
        <template #button-content>
          <span class="navbar-site-button">{{ currentProjectRef }}</span> <i class="el-icon-sort"></i>
        </template>

        <b-dropdown-header id="dropdown-header-label">
          {{ $t('navbar.myProjects') }}
        </b-dropdown-header>

        <b-dropdown-item
          v-for="project in projects"
          :key="project.ref"
          :value="project.ref"
          @click.prevent="changeProject(project)"
        >
          <template>
            <span class="font-weight-bold">{{project.ref}}</span><br><span class="text-info font-weight-light">{{project.projectShortname}}</span>
          </template>

        </b-dropdown-item>
      </b-dropdown>

      <!-- currentCard: {{ this.$store.getters.currentCard }} currentStatsView: {{ this.$store.getters.currentStatsView }} Section: {{ this.$store.getters.section }} -->
      <!-- Inline buttons on sm -->
      <div class="ml-auto">
        <b-nav-item
          class="d-inline-block d-md-none"
          :to="{path: '/scan'}"
        >
          <font-awesome-icon
            :icon="
            ['fal', 'qrcode'
            ]"
            class="fa-lg"
          />
        </b-nav-item>
        <b-nav-item
          class="d-inline-block d-md-none"
          to="/search/mobile"
          @click="search"
        >
          <font-awesome-icon
            :icon="
            ['fal', 'search'
            ]"
            class="fa-lg"
          />
        </b-nav-item>

        <b-nav-item
          class="d-inline-block d-md-none"
          :to="this.$store.getters.lastSubsectionRouteInSection.notifications"
        >

          <el-badge
            is-dot
            class="item"
            v-if="notifications"
          >
            <font-awesome-icon
              :icon="
            ['fal', 'bell'
            ]"
              class="fa-lg"
            />
          </el-badge>

          <font-awesome-icon
            :icon="
            ['fal', 'bell'
            ]"
            class="fa-lg"
            v-else
          />

        </b-nav-item>

      </div>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <!-- Collapse menu -->
      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">

          <b-nav-item
            class="d-none d-md-block ml-3"
            :to="{path: '/scan'}"
          >
            <font-awesome-icon
              :icon="
            ['fal', 'qrcode'
            ]"
              class="fa-lg d-xl-none"
            />
            <span class="d-none d-xl-inline-block ml-3">Scan</span>
          </b-nav-item>

          <b-nav-item
            class="d-none d-md-block ml-3"
            :to="this.$store.getters.lastSubsectionRouteInSection.explore"
            @click="explore"
          >
            <font-awesome-icon
              :icon="
            ['fal', 'search'
            ]"
              class="fa-lg d-xl-none"
            />
            <span class="d-none d-xl-inline-block ml-3">{{ $t('navbar.nav.explore') }}</span>
          </b-nav-item>

          <b-nav-item
            class="ml-3 ml-md-2"
            :to="'/stats'"
          >
            <font-awesome-icon
              :icon="
            ['fal', 'chart-line'
            ]"
              class="fa-lg d-none d-md-block d-xl-none"
            />

            <span class="d-none d-xl-inline-block ml-3">{{ $t('navbar.stats') }}</span>
          </b-nav-item>

          <b-nav-item
            class="d-none d-md-block ml-3"
            :to="this.$store.getters.lastSubsectionRouteInSection.notifications"
          >
            <el-badge
              is-dot
              class="item d-xl-none"
              v-if="notifications"
            >
              <font-awesome-icon
                :icon="
            ['fal', 'bell'
            ]"
                class="fa-lg d-xl-none"
              />

            </el-badge>

            <font-awesome-icon
              :icon="
            ['fal', 'bell'
            ]"
              class="fa-lg d-xl-none"
              v-else
            />

            <el-badge
              is-dot
              v-if="notifications"
            >
              <span class="d-none d-xl-inline-block ml-3">{{ $t('navbar.notifications') }}</span>
            </el-badge>
            <span
              v-else
              class="d-none d-xl-inline-block ml-3"
            >{{ $t('navbar.notifications') }}</span>

            <!-- <el-badge
              is-dot
              class="item d-xl-none"
            >
              <font-awesome-icon
                :icon="
            ['fal', 'bell'
            ]"
                class="fa-lg d-xl-none"
              />
            </el-badge> -->

          </b-nav-item>

          <div v-if="admin">
            <b-nav-item
              class="ml-3 ml-md-2"
              :to="this.$store.getters.lastSubsectionRouteInSection.admin"
            >
              <!-- <span
                v-if="!isTablet"
                class="d-none d-md-inline-block ml-3"
              >{{ $t('navbar.admin') }}</span> -->
              <span
                v-if="!isTablet"
                class="d-sm-inline-block d-lg-none d-xl-inline-block ml-2"
              >{{ $t('navbar.admin') }}</span>

              <font-awesome-icon
                :icon="['fal', 'hammer']"
                class="fa-lg d-none d-md-inline-block  d-lg-inline-block d-xl-none"
              />
            </b-nav-item>
          </div>

          <b-nav-item-dropdown
            class="ml-4"
            id="dropdown-lang"
            right
          >
            <template #button-content>
              {{ lang }}
            </template>

            <b-dropdown-item
              href="#"
              @click.prevent="setLocale('fr')"
            >
              <img
                :src="require('@/assets/img/flag/flag_fr.svg')"
                alt="French flag"
              >
              <span class="ml-2">{{ $t('navbar.french') }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              href="#"
              @click.prevent="setLocale('en')"
            >
              <img
                :src="require('@/assets/img/flag/flag_en.svg')"
                alt="English flag"
              >
              <span class="ml-2">{{ $t('navbar.english') }}</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown
            right
            id="dropdown-account"
            class="ml-3 ml-md-2 border-none"
          >
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <span class="d-none d-md-inline-block ml-4">
                {{ userInitials }}
                <font-awesome-icon
                  :icon="
            ['fas', 'caret-down'
            ]"
                  class="fa-xs"
                />
              </span>
              <span class="d-inline-block d-md-none ml-2">{{ $t('navbar.myAccount') }}</span>
            </template>

            <b-dropdown-item
              href="#"
              :to="{ name: 'home' }"
            >
              <span class="dropdown-item-account">{{ $t('navbar.home') }}</span>
            </b-dropdown-item>
            <b-dropdown-divider class="d-none d-md-block"></b-dropdown-divider>
            <b-dropdown-item
              href="
              #"
              :to="{ name: 'settings' }"
            ><span class="dropdown-item-account">{{ $t('navbar.settings') }}</span></b-dropdown-item>
            <b-dropdown-divider class="d-none d-md-block"></b-dropdown-divider>
            <b-dropdown-item
              href="#"
              :to="{ name: 'helphome' }"
            ><span class="dropdown-item-account">{{ $t('navbar.support') }}</span></b-dropdown-item>
            <b-dropdown-divider class="d-none d-md-block"></b-dropdown-divider>
            <b-dropdown-item
              href="#"
              :to="{ name: 'logout' }"
              class="dropdown-item-logout"
            >
              <el-button
                type="warning"
                size="small"
              >{{ $t('navbar.logout') }}</el-button>
            </b-dropdown-item>
          </b-nav-item-dropdown>

        </b-navbar-nav>

      </b-collapse>
    </b-navbar>
    <!-- <Alert /> -->
  </div>
</template>

<script>
// import Alert from '../components/Alerts/Alert.vue';

export default {
  name: 'Navbar',
  components: {
    // Alert,
  },
  data() {
    return {
      lang: this.$i18n.locale,
      projects: [],
      currentProjectRef: '',
      admin: false,
      userInitials: this.$store.getters.loggedUser.initials,
      notifications: false,
      isTablet: false,
    };
  },
  computed: {
    routeName() {
      const { path } = this.$route;
      const pathArray = path.split('/');
      const section = pathArray[1];
      return this.$t(`routes.section.${section}`);
    },
  },
  mounted() {
    // Get all projects to feed dropdowm
    this.projects = this.$store.getters.projects;
    const clgStyle = 'color: red;';
    console.log('%c' + 'projects', clgStyle);
    console.log(this.projects);
    // Get current project
    this.currentProjectRef = this.$store.getters.currentProject.ref;
    // Set admin (so that the admin icon appears in nav)
    const role = this.$store.getters.currentProject.role;
    if (role == 'admin') {
      this.admin = true;
    } else {
      this.admin = false;
    }
    // this.admin = this.$store.getters.currentProject.admin;

    this.checkIfTablet();
    window.addEventListener('resize', this.checkIfTablet);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfTablet);
  },
  methods: {
    checkIfTablet() {
      const width = window.innerWidth;
      this.isTablet = width >= 768 && width <= 1024; // Adjust the pixel values as needed
    },
    search() {
      // Show explore or search filters on mobile
      // let ele = document.getElementById('wrapper-filter');
      const sidebarFilter = document.getElementById('sidebar-filter');
      // const infiniteScrollTable = document.getElementById(
      //   'infiniteScrollTable',
      // );
      if (window.innerWidth < 768) {
        // Set current tab to "tiny"
        this.$store.commit('setCurrentCard', 'tiny');
        const clgStyle = 'color: red;';
        console.log('%c' + 'DISPLAY FILTERS ONLY', clgStyle);
        sidebarFilter.setAttribute('style', 'width: 100% !important;');
        // sidebarFilter.setAttribute('style', 'z-index: 2011 !important;');
        // infiniteScrollTable.style.display = 'none';
      }
      // :to="this.$store.getters.lastSubsectionRouteInSection.explore"
    },
    explore() {
      const sidebarFilter = document.getElementById('sidebar-filter');
      const infiniteScrollTable = document.getElementById(
        'infiniteScrollTable'
      );
      if (window.innerWidth >= 768) {
        // infiniteScrollTable.style.display = "block";
        // sidebarFilter.setAttribute("style", "width: 380px !important;");
      }
    },
    changeProject(obj) {
      console.log(obj);
      // Set ref in dropdown
      this.currentProjectRef = obj.ref;
      // Set current project in store
      this.$store.commit('setCurrentProject', obj);
      // Set admin (so that the admin icon appears in nav)
      if (obj.role == 'admin') {
        this.admin = true;
      } else {
        this.admin = false;
      }
      // this.admin = obj.admin;
      // Reset location filters
      // TODO: reset other filters
      this.$store.commit('setBuilding', { id: null, label: null });
      this.$store.commit('setLevel', '');
      this.$store.commit('setZone', '');
      this.$store.commit('setSerial', '');
      this.$store.commit('setDefinition', { value: null, label: null });
      this.$store.commit('setLocation', []);
      // Enpry selection and get out of selectionMode
      this.$store.commit('deleteSelection');
      this.$store.commit('setSelectionMode', false);
      // Return to Home page
      window.location.reload();
      // this.$router.go();
    },
    setLocale(locale) {
      // Set i18n locale global variable
      this.$i18n.locale = locale;
      this.lang = locale;
      // Store locale in localstorage
      localStorage.setItem('lang', locale);
    },
  },
};
</script>
<style>

.dropdown-menu { 
    border-radius: 12px !important;
}
#dropdown-account > .dropdown-menu,
#dropdown-lang > .dropdown-menu {
  border: none !important;
}
@media (min-width: 768px) { 
  #dropdown-account > .dropdown-menu,
  #dropdown-lang > .dropdown-menu {
    border: 0.0625rem solid rgba(52, 58, 64, 0.15) !important;
  }
} 
.dropdown-item-account {
  font-size: 14px;
  font-weight: 400;
}
.dropdown-item-logout:focus > a, .dropdown-item-logout:hover > a {
  background-color: #fff !important;
}
</style>