const state = {
  isLoggedIn: false,
  userLogged: {
    uuid: '',
    initials: '',
  },
  siteAccess: [],
  sectionAccess: {
    admin: false,
  },
  featureAccess: {
    entity: false,
    user: false,
  },
};

const getters = {
  isLoggedIn: (state) => {
    return state.isLoggedIn;
  },
  loggedUser: (state) => {
    return state.userLogged;
  },
  siteAccess: (state) => {
    return state.siteAccess;
  },
  sectionAccess: (state) => {
    return state.sectionAccess;
  },
};

const mutations = {
  logIn(state, val) {
    state.isLoggedIn = val;
  },
  saveUserLogged(state, loggedUser) {
    state.userLogged = loggedUser;
  },
  setSectionAccess(state, val) {
    state.sectionAccess.admin = val;
  },
  clearUserLogged(state) {
    state.userLogged = {
      uuid: '',
      initials: '',
    };
  },
};

const actions = {
  saveUserLogged(context, loggedUser) {
    context.commit('saveUserLogged', loggedUser);
  },
  clearUserLogged(context) {
    context.commit('clearUserLogged');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
