import swal from 'sweetalert2';
import i18n from './i18n';
import router from '@/router';
import store from '@/store';

const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: true,
});

export const globalSwal = {
  serverError: () => {
    swalBootsrap.fire({
      icon: 'error',
      title: i18n.t('error.oops'),
      text: i18n.t('error.server'),
      footer: '<a href="">Why do I have this issue?</a>',
    });
  },
  errorSimple: (title) => {
    setTimeout(() => {
      swalBootsrap.fire({
        icon: 'error',
        title: i18n.t(title),
      });
    }, 300);
  },
  error: (msg) => {
    const clgStyle = 'color: red;';
    console.log('%c' + 'HERE IN ERROR', clgStyle);
    setTimeout(() => {
      let confirmButtonText = 'Ok';
      let exitUser = false;
      if (msg == 'error.accessDenied') {
        confirmButtonText = 'Log Out';
        exitUser = true;
      }
      swalBootsrap
        .fire({
          icon: 'error',
          title: i18n.t('error.oops'),
          html: i18n.t(msg),
          confirmButtonText,
          footer: i18n.t('error.persists'),
          allowOutsideClick: false,
          allowEscapeKey: false,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed && exitUser) {
            swalBootsrap
              .fire({
                icon: 'success',
                title: i18n.t('logout.greetings'),
                showConfirmButton: false,
                timer: 1500,
              })
              .then(() => {
                // Clear last route from store
                store.commit('SAVE_CONTEXT', '');
                // Reroute to login page
                router.push('/auth/login');
              });
          }
        });
    }, 300);
  },
  errorOBS: (title, text = null, footer = null) => {
    setTimeout(() => {
      if (text != null && footer != null) {
        swal.fire({
          icon: 'error',
          title: i18n.t(title),
          html: i18n.t(text),
          footer: i18n.t(footer),
          confirmButtonClass: 'btn btn-primary',
        });
      } else if (text != null && footer == null) {
        swal.fire({
          icon: 'error',
          title: i18n.t(title),
          html: i18n.t(text),
        });
      } else if (text == null && footer != null) {
        swal.fire({
          icon: 'error',
          title: i18n.t(title),
          footer: i18n.t(footer),
        });
      } else if (text == null && footer == null) {
        swal.fire({
          icon: 'error',
          title: i18n.t(title),
        });
      }
    }, 300);
  },
  success(title, text) {
    setTimeout(() => {
      swalBootsrap.fire({
        icon: 'success',
        title: i18n.t(title),
        text: i18n.t(text),
        showConfirmButton: false,
        timer: 1500,
      });
    }, 300);
  },
  successSimple(title, text) {
    setTimeout(() => {
      swalBootsrap.fire({
        icon: 'success',
        title: i18n.t(title),
        showConfirmButton: false,
        timer: 1500,
      });
    }, 300);
  },
};
