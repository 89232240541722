import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store';
import checkMaintenanceMode from '@/middleware/checkMaintenanceMode';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  // Check if the app is in maintenance mode
  await checkMaintenanceMode(to, from, async (redirect) => {
    if (redirect) {
      next(redirect);
    } else {
      // If the route requires authentication
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        // Check if the user is logged in
        if (!store.getters.isLoggedIn) {
          next({ name: 'Login' });
        } else {
          next(); // Proceed to the route
        }
      } else {
        next(); // Proceed to the route
      }
    }
  });
});

export default router;
