import axiosResource from '@/utils/axiosResource';
import store from '../store/index.js';

const checkMaintenanceMode = async (to, from, next) => {
  const clgStyle = 'color: red;';
  console.log('%c' + 'checkMaintenanceMode', clgStyle);
  try {
    const response = await axiosResource.get('maintenance/maintenance-status');
    const isMaintenance = response.data.isMaintenance;

    const userUUid = store.getters.loggedUser.uuid;

    // Valid users who can access during maintenance
    const validUUIDs = [
      'User1234-abcd-1234-abcd-8222DUBAI888',
      'b9f56667-52e3-49fe-871c-506436a0c4b4',
    ];

    console.log('%c' + `isMaintenance=${isMaintenance}`, clgStyle);
    console.log('%c' + `userUUid=${userUUid}`, clgStyle);
    console.log('%c' + `to.name=${to.name}`, clgStyle);

    if (
      isMaintenance &&
      (to.name === 'PasswordInit' || to.name === 'PasswordSetup')
    ) {
      return next({ name: 'Maintenance' });
    }

    if (validUUIDs.includes(userUUid)) {
      next();
    } else if (
      isMaintenance &&
      to.name !== 'Login' &&
      to.name !== 'Maintenance'
    ) {
      return next({ name: 'Maintenance' });
    }

    next();
  } catch (error) {
    console.error('Failed to check maintenance mode:', error);
    next();
  }
};

export default checkMaintenanceMode;
