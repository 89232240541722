<template>
  <div>
    <router-view />

  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {};
  },
};
</script>
<style scoped>
#app {
  position: relative;
  top: 0px;
}
</style>
