const state = {
  currentCard: 'tiny',
};

const getters = {
  currentCard: (state) => {
    return state.currentCard;
  },
};

const mutations = {
  setCurrentCard(state, card) {
    state.currentCard = card;
  },
};

export default {
  state,
  getters,
  mutations,
};
