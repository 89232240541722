import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Import native language json
import elementLocale from 'element-ui/lib/locale';
import gosoEN from './locales/en.json';
import gosoFR from './locales/fr.json';
import elementEN from './locales/element_en';
import elementFR from './locales/element_fr';

Vue.use(VueI18n);

function detectBrowserLanguage() {
  let lang = window.navigator.language;
  lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;
  let shortLang = lang;
  if (shortLang.indexOf('-') !== -1) ({ shortLang } = shortLang.split('-'));
  const supportedLanguages = ['fr', 'en'];
  if (!supportedLanguages.includes(shortLang)) shortLang = 'en'; // Set default language
  return shortLang;
}

function setLang() {
  const lang = localStorage.getItem('lang');
  if (lang === null) {
    return detectBrowserLanguage();
  }
  return lang;
}

// Add languages to messages object
const messages = {
  en: {
    ...elementEN,
    ...gosoEN,
  },
  fr: {
    ...elementFR,
    ...gosoFR,
  },
};

const i18n = new VueI18n({
  locale: setLang(),
  fallbackLocale: 'en',
  messages,
});

// Configure elementUI language conversion relationship
elementLocale.i18n((key, value) => i18n.t(key, value));

export default i18n;
