const state = {
  selectionMode: false,
};

const getters = {
  selectionMode: (state) => {
    return state.selectionMode;
  },
};

const mutations = {
  setSelectionMode(state, value) {
    state.selectionMode = value;
  },
};

export default {
  state,
  getters,
  mutations,
};
