import Vue from 'vue';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';

// PRO SOLID
import {
  faCircleInfo as fasCircleInfo,
  faChartPie as fasChartPie,
  faChartLine as fasChartLine,
  faChartMixed as fasChartMixed,
  faChartScatter as fasChartScatter,
  faMap as fasMap,
  faGrid as fasGrid,
  faBarsFilter as fasBarsFilter,
  faPlus as fasPlus,
  faXmark as fasXmark,
  faCheck,
  faFlag as fasFlag,
  faCirclePlay,
  faCaretDown,
  faList as fasList,
  faCircle as fasCircle,
  faFilePdf as fasFilePdf,
  faSort as fasSort,
  faFileArrowDown,
  faStop,
  faSquare as fasSquare,
  faMapLocationDot,
  faLocationDot,
  faPeriod,
  faThumbsUp as fasThumbsUp,
  faPersonWalking as fasPersonWalking,
  faLock as fasLock,
  faBan as fasBan,
  faImages,
} from '@fortawesome/pro-solid-svg-icons';

// PRO REGULAR
import {
  faImage as farImage,
  faPlus as farPlus,
  faSpinner,
  faEllipsis as farEllipsis,
  faMap as farMap,
  faFilePdf as farFilePdf,
  faClock as farClock,
  faPowerOff,
  faArrowsRotate,
  faThumbsUp as farThumbsUp,
  faPersonWalking as farPersonWalking,
  faLock as farLock,
  faBan as farBan,
  faStopwatch,
  faBarsFilter as farBarsFilter,
  faArrowLeft as farArrowLeft,
  faArrowRight as farArrowRight,
  faAngleDown,
  faAngleUp,
  faRotateLeft,
  faRotateRight,
  faSquare,
  faText,
  faCircle as farCircle,
  faArrowDown,
  faTrashCan,
  faEraser,
  faArrowsUpDownLeftRight,
} from '@fortawesome/pro-regular-svg-icons';

// PRO LIGHT
import {
  faHammer,
  faFilters,
  faDiagramProject,
  faCircleStar as falCircleStar,
  faHexagon as falHexagon,
  faCertificate as falCertificate,
  faRhombus as falRhombus,
  faStarSharp as falStarSharp,
  faHomeBlank,
  faMessages,
  faMessageSms,
  faAt as falAt,
  faEnvelope,
  faPhone,
  faPaperclip,
  faSquare as falSquare,
  faFlag as falFlag,
  faCircleUser,
  faRetweet,
  faFile as falFile,
  faList as falList,
  faHeadSideBrain,
  faComment,
  faArrowRightFromBracket,
  faPenSquare,
  faTrashCan as falTrashCan,
  faLineColumns,
  faPeriod as falPeriod,
  faCode,
  faCalendarCheck,
  faTimer,
  faThumbsUp,
  faLock,
  faPersonWalking,
  faBan,
  faHome,
  faSignOut,
  faUser,
  faMapMarkedAlt,
  faChartNetwork,
  faChartPie,
  faChartLine,
  faUserChart,
  faExclamationTriangle,
  faHouseDamage,
  faQuestionCircle,
  faListAlt,
  faSitemap,
  faTools,
  faUsers,
  faExchangeAlt,
  faBell,
  faEnvelopeOpen,
  faEdit,
  faTrash,
  faBarcode,
  faArrowLeft,
  faArrowRight,
  faFilter,
  faCog,
  faQrcode,
  faUndo,
  faSearch,
  faCaretSquareDown,
  faTable,
  faBuilding,
  faPlus,
  faMinus,
  faArrowAltLeft,
  faShieldCheck,
  faShield,
  faClock,
  faExclamationCircle,
  faInfoCircle,
  faMobileAndroid,
  faCircle,
  faSlash,
  faSiren,
  faSirenOn,
  faDrawSquare,
  faHeart,
  faDroplet,
  faMap,
  faFilePdf,
  faEllipsis,
  faShareFromSquare,
  faAngleLeft,
  faAngleRight,
  faToolbox,
  faMagnifyingGlass,
  faCalendarClock,
  faFileExcel,
} from '@fortawesome/pro-light-svg-icons';

// BRANDS
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

// DUOTONE
import {
  faCircleExclamation,
  faScrewdriver,
  faFileLines,
  faLightbulbOn,
  faUserCircle,
  faCheckCircle,
  faBan as fadBan,
  faHourglassStart,
  faShieldAlt,
  faMemoPad,
  faThumbsUp as fadThumbsUp,
  faPersonWalking as fadPersonWalking,
  faLock as fadLock,
  faSort as fadSort,
  faList as fadList,
  faFile,
  faCircleBolt,
  faImages as fadImages,
} from '@fortawesome/pro-duotone-svg-icons';

// THIN
import {
  faRhombus,
  faFlag as fatFlag,
  faAt,
  faMessage,
  faMap as fatMap,
  faBarsFilter,
  faList,
  faGrid,
  faGrid2,
  faSquare as fatSquare,
  faBullseyePointer,
  faCircle as fatCircle,
  faBagShopping,
  faMagnifyingGlass as fatMagnifyingGlass,
  faTrashCan as fatfaTrashCan,
  faArrowDownToLine as fatfaArrowDownToLine,
  faFileLines as fatFileLines,
  faXmark,
  faPeriod as fatPeriod,
  faBan as fatBan,
  faPersonWalking as fatPersonWalking,
  faThumbsUp as fatThumbsUp,
  faLock as fatLock,
} from '@fortawesome/pro-thin-svg-icons';

// SHARP THIN
import { faLocationDot as fastLocationDot } from '@fortawesome/sharp-thin-svg-icons';

// SHARP SOLID
import { faLocationDot as fassLocationDot } from '@fortawesome/sharp-solid-svg-icons';

library.add(
  fassLocationDot,
  fastLocationDot,
  faCircleExclamation,
  faScrewdriver,
  faHammer,
  faFilters,
  faDiagramProject,
  fasCircleInfo,
  fasChartPie,
  fasChartLine,
  fasChartMixed,
  fasChartScatter,
  falCircleStar,
  falHexagon,
  falCertificate,
  falStarSharp,
  falRhombus,
  fasMap,
  fasGrid,
  fasBarsFilter,
  faHomeBlank,
  faMessages,
  faMessageSms,
  falAt,
  faEnvelope,
  faPhone,
  faPaperclip,
  faRhombus,
  falSquare,
  fasXmark,
  faCheck,
  farEllipsis,
  farMap,
  farFilePdf,
  farClock,
  fasFlag,
  falFlag,
  fatFlag,
  faCirclePlay,
  faCaretDown,
  faCircleUser,
  faPowerOff,
  faAt,
  faMessage,
  faRetweet,
  falFile,
  falList,
  farImage,
  faImages,
  fadImages,
  faCircleBolt,
  faFile,
  faHeadSideBrain,
  faComment,
  faArrowRightFromBracket,
  faArrowsRotate,
  faPenSquare,
  faLineColumns,
  falTrashCan,
  fasList,
  fatMap,
  farThumbsUp,
  farPersonWalking,
  farLock,
  farBan,
  falPeriod,
  faLightbulbOn,
  fadList,
  fatThumbsUp,
  fatBan,
  fatPersonWalking,
  fatLock,
  fasPersonWalking,
  fasLock,
  fasBan,
  faCode,
  faPeriod,
  fatPeriod,
  faLocationDot,
  faMapLocationDot,
  faXmark,
  faFileLines,
  fatFileLines,
  fatMagnifyingGlass,
  fatfaArrowDownToLine,
  fatfaTrashCan,
  fasSquare,
  faStop,
  faFileArrowDown,
  faArrowsUpDownLeftRight,
  faEraser,
  faTrashCan,
  faArrowDown,
  farCircle,
  faRotateLeft,
  faRotateRight,
  faText,
  fatSquare,
  faFileExcel,
  faMagnifyingGlass,
  faToolbox,
  fasSort,
  fadSort,
  fasFilePdf,
  faBagShopping,
  faAngleLeft,
  faAngleRight,
  faShareFromSquare,
  fatCircle,
  faFilePdf,
  faMap,
  fasCircle,
  faEllipsis,
  faSquare,
  faHeart,
  faDroplet,
  faDrawSquare,
  faSirenOn,
  faSiren,
  faSlash,
  fadLock,
  fadPersonWalking,
  fadThumbsUp,
  faMemoPad,
  faBullseyePointer,
  faGrid,
  faGrid2,
  faList,
  faCalendarCheck,
  faCalendarClock,
  faTimer,
  faThumbsUp,
  fasThumbsUp,
  faPersonWalking,
  faLock,
  faBan,
  farArrowLeft,
  farArrowRight,
  faAngleDown,
  faAngleUp,
  faBarsFilter,
  farBarsFilter,
  faCircle,
  faInfoCircle,
  faShieldAlt,
  faHome,
  faSignOut,
  faUser,
  faUserCircle,
  faMapMarkedAlt,
  faChartNetwork,
  faChartPie,
  faChartLine,
  faUserChart,
  faCheckCircle,
  faHourglassStart,
  faExclamationTriangle,
  faHouseDamage,
  faQuestionCircle,
  faListAlt,
  faSitemap,
  faTools,
  faBell,
  faExchangeAlt,
  faEnvelopeOpen,
  faEdit,
  faTrash,
  faSpinner,
  faStopwatch,
  faBarcode,
  faUsers,
  faArrowLeft,
  faArrowRight,
  faShieldCheck,
  faShield,
  faClock,
  faCog,
  faFilter,
  fadBan,
  faExclamationCircle,
  faQrcode,
  faSearch,
  faCaretSquareDown,
  faInstagram,
  faTable,
  faBuilding,
  faUsers,
  faPlus,
  farPlus,
  fasPlus,
  faMinus,
  faArrowAltLeft,
  faUndo,
  faMobileAndroid
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

/* <font-awesome-icon icon="fa-solid fa-user-secret" /> */

// DEPRECATED
/* <font-awesome-icon
  :icon="['fal', 'qrcode']"
  class="fa-lg"
/> */
