const state = {
  alert: {
    acknowledged: false,
  },
};

const getters = {
  alert: (state) => {
    return state.alert;
  },
};

const mutations = {
  setAlertAcknowledgment(state, val) {
    state.alert.acknowledged = val;
  },
};

export default {
  state,
  getters,
  mutations,
};
