// store/modules/category.js
import axiosResource from '@/utils/axiosResource';
import { EventBus } from '@/eventBus';
// import swalBootstrap from 'sweetalert2';
import { globalSwal } from '@/plugins/globalSwal';
import swal from 'sweetalert2';
const swalBootstrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

const state = {
  categories: [],
  qttFamily: 0,
};

const getters = {
  categories: (state) => state.categories,
  qttFamily: (state) => state.qttFamily,
};

const actions = {
  async fetchCategories({ commit }, projectUuid) {
    try {
      const response = await axiosResource.get(
        `/admin/categories/all/${projectUuid}/true`
      );
      if (response.data.success) {
        commit('SET_CATEGORIES', response.data.results);
        commit('setQttFamily', response.data.results.length);
      } else {
        // swalBootstrap.fire('Error', response.data.message, 'error');
        const clgStyle = 'color: red;';
        console.log('%c' + 'response', clgStyle);
        console.log(response);
        EventBus.$emit('show-integrity-modal'); // Emit event to show the modal
        // if (response.data.msg == 'error.noIntegrityToken') {
        //   this.$bvModal.show('modal-integrity');
        // } else {
        //   this.$globalSwal.error(err.response.data.msg);
        // }
      }
    } catch (error) {
      EventBus.$emit('show-integrity-modal'); // Emit event to show the modal
      // swalBootstrap.fire(
      //   'Error',
      //   error.response?.data?.msg || 'An error occurred',
      //   'error'
      // );
    } finally {
      commit('loading', false); // Ensure loading is false after try/catch
    }
  },
  async deleteCategory({ commit }, { type, itemUuid, node }) {
    const result = await swalBootstrap.fire({
      title: `Are you sure you want to delete this ${type}?`,
      text: 'This action cannot be undone.',
      showConfirmButton: true,
      showCancelButton: true,
      icon: 'question',
    });

    if (result.isConfirmed) {
      try {
        await axiosResource.put(`/admin/categories/${type}/${itemUuid}/`, null);
        commit('REMOVE_CATEGORY', node.data.uuid);
        globalSwal.successSimple('success.done');
      } catch (error) {
        console.log(error);
        swalBootstrap.fire(
          'Error',
          'There was an error deleting the category.',
          'error'
        );
        console.error(error);
      }
    } else if (result.isDenied) {
      swalBootstrap.fire('Cancelled', 'The category was not deleted.', 'info');
    }
  },
  async updateCategory({ commit }, { type, itemUuid, categoryData }) {
    commit('SET_LOADING', true);
    try {
      const response = await axiosResource.post(
        `/admin/categories/${type}/${itemUuid}/`,
        categoryData
      );
      if (response.data.success) {
        commit('UPDATE_CATEGORY', { itemUuid, categoryData });
        // globalSwal.successSimple('success.done');
      } else {
        swalBootstrap.fire('Error', response.data.message, 'error');
      }
    } catch (error) {
      swalBootstrap.fire(
        'Error',
        error.response?.data?.msg || 'An error occurred',
        'error'
      );
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createCategory({ commit }, categoryData) {
    try {
      const response = await axiosResource.post(
        `/admin/categories/${categoryData.type}/`,
        categoryData
      );
      if (response.data.success) {
        commit('ADD_CATEGORY', categoryData);
        globalSwal.successSimple('success.done');
      } else {
        swalBootstrap.fire('Error', response.data.message, 'error');
      }
    } catch (error) {
      swalBootstrap.fire(
        'Error',
        error.response?.data?.msg || 'An error occurred',
        'error'
      );
    }
  },
};

const mutations = {
  setQttFamily(state, qtt) {
    state.qttFamily = qtt;
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  REMOVE_CATEGORY(state, uuid) {
    const findAndRemove = (items) => {
      for (let i = 0; i < items.length; i++) {
        if (items[i].uuid === uuid) {
          if (items[i].level === 1) {
            state.qttFamily--;
          }
          items.splice(i, 1);
          return true;
        }
        if (items[i].children && findAndRemove(items[i].children)) {
          return true;
        }
      }
      return false;
    };
    findAndRemove(state.categories);
  },
  UPDATE_CATEGORY(state, { itemUuid, categoryData }) {
    const clgStyle = 'color: orange;';
    console.log('%c' + 'UPDATE_CATEGORY', clgStyle);
    // commit('SET_LOADING', false);
    const findAndUpdate = (items) => {
      for (let i = 0; i < items.length; i++) {
        if (items[i].uuid === itemUuid) {
          console.log('%c' + 'before', clgStyle);
          console.log(items[i]);
          items[i].label = categoryData.name;
          items[i].color = categoryData.color;
          console.log('%c' + 'after', clgStyle);
          console.log(items[i]);
          return true;
        }
        if (items[i].children && findAndUpdate(items[i].children)) {
          return true;
        }
      }
      return false;
    };
    findAndUpdate(state.categories);
  },
  ADD_CATEGORY(state, categoryData) {
    const addToParent = (items, parentUuid) => {
      for (let item of items) {
        if (item.uuid === parentUuid) {
          item.children.push({
            uuid: categoryData.uuid,
            name: categoryData.name,
            label: categoryData.name,
            color: categoryData.color,
            children: [],
          });
          state.qttFamily++;
          return true;
        }
        if (item.children && addToParent(item.children, parentUuid)) {
          return true;
        }
      }
      return false;
    };

    switch (categoryData.type) {
      case 'family':
        state.categories.push({
          uuid: categoryData.uuid,
          name: categoryData.name,
          label: categoryData.name,
          color: categoryData.color,
          children: [],
        });
        state.qttFamily++;
        break;
      case 'activity':
        addToParent(state.categories, categoryData.familyUuid);
        break;
      case 'specialty':
        state.categories.forEach((family) =>
          addToParent(family.children, categoryData.activityUuid)
        );
        break;
      case 'remark':
        state.categories.forEach((family) =>
          family.children.forEach((activity) =>
            addToParent(activity.children, categoryData.specialtyUuid)
          )
        );
        break;
    }
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
