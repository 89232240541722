<template>
  <div>

    <nav-bar />
    <div
      id="wrapper-filter"
      class="wrapper-filter"
    >

      <loading-panel></loading-panel>

      <router-view />

    </div>
    <!-- <footer-bar /> -->

  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue';

export default {
  name: 'default',
  components: {
    NavBar,
  },
};
</script>
