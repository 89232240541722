export default {
  install(Vue) {
    Vue.prototype.$section = function () {
      return {
        admin: [
          {
            title: this.$t('adminSideBar.project'),
            icon: ['fal', 'diagram-project'],
            subsection: 'project',
            to: '/admin/project/projectlist',
            tooltip: 'Project management',
          },
          {
            title: this.$t('adminSideBar.entity'),
            icon: ['fal', 'list-alt'],
            subsection: 'entity',
            to: '/admin/entity/entitylist',
            tooltip: 'Entity management',
          },
          {
            title: this.$t('adminSideBar.user'),
            icon: ['fal', 'users'],
            subsection: 'user',
            to: '/admin/user/userlist',
            tooltip: 'User management',
          },
          {
            title: this.$t('adminSideBar.building'),
            icon: ['fal', 'building'],
            subsection: 'building',
            to: '/admin/building/buildingconfig',
            tooltip: 'Building management',
          },
          {
            title: this.$t('adminSideBar.category'),
            icon: ['fal', 'sitemap'],
            subsection: 'category',
            to: '/admin/category/categoryconfig',
            tooltip: 'Category Management',
          },
          {
            title: this.$t('adminSideBar.definition'),
            icon: ['fal', 'draw-square'],
            subsection: 'definition',
            to: '/admin/definition/definitionconfig',
            tooltip: 'Object definition',
          },
          // {
          //   title: this.$t('adminSideBar.flag'),
          //   icon: ['fal', 'flag'],
          //   subsection: 'flag',
          //   to: '/admin/flag/flagconfig',
          //   tooltip: 'Flag',
          // },
          // {
          //   title: this.$t('adminSideBar.checklist'),
          //   icon: ['fal', 'list'],
          //   subsection: 'checklist',
          //   to: '/admin/checklist/checklistconfig',
          //   tooltip: 'Checklist',
          // },
          // {
          //   title: this.$t('adminSideBar.objective'),
          //   icon: ['fal', 'calendar-clock'],
          //   subsection: 'duedate',
          //   to: '/admin/duedate/duedateconfig',
          //   tooltip: 'Object definition',
          // },
          // {
          //   title: this.$t('adminSideBar.ml'),
          //   icon: ['fal', 'head-side-brain'],
          //   subsection: 'ml',
          //   to: '/admin/ml/mlConfig',
          //   tooltip: 'Machine Learning',
          // },
          // {
          //   title: this.$t('adminSideBar.request'),
          //   icon: ['fal', 'retweet'],
          //   subsection: 'ml',
          //   to: '/admin/request/requestConfig',
          //   tooltip: 'Follow up',
          // },
          // {
          //   title: this.$t('adminSideBar.support'),
          //   icon: ['fal', 'question-circle'],
          //   subsection: 'support',
          //   to: '/admin/support/helpadmin',
          //   tooltip: 'Need help ?',
          // },
        ],
        search: [
          {
            title: 'Filter deficiencies',
            icon: ['fal', 'house-damage'],
            subsection: 'deficiency',
            to: '/search/deficiency/filterdeficiency',
            tooltip: 'Filter deficiencies',
          },
          {
            title: 'Support',
            icon: ['fal', 'question-circle'],
            subsection: 'support',
            to: '/search/support/helpsearch',
            tooltip: 'Need help ?',
          },
        ],
        stats: [
          {
            title: this.$t('statsSideBar.global'),
            icon: ['fal', 'chart-pie'],
            subsection: 'global',
            to: '/stats/global/globalStats',
            tooltip: 'Global stats',
          },
          {
            title: this.$t('statsSideBar.advanced'),
            icon: ['fal', 'chart-line'],
            subsection: 'advanced',
            to: '/stats/advanced/advancedStats',
            tooltip: 'Advanced stats',
          },
          // {
          //   title: this.$t('statsSideBar.users'),
          //   icon: ['fal', 'user-chart'],
          //   subsection: 'users',
          //   to: '/stats/users/usersStats',
          //   tooltip: 'Users stats',
          // },
        ],
        deficiency: [
          {
            title: 'ITEM GOSO 1',
            icon: ['fal', 'sitemap'],
            to: 'entitylist',
            tooltip: 'Entity management',
          },
          {
            title: 'ITEM GOSO 2',
            icon: ['fal', 'users'],
            to: '/alerts/notificationlist',
            tooltip: 'User management',
          },
        ],
        notification: [
          {
            title: this.$t('notificationSideBar.notifications'),
            icon: ['fal', 'bell'],
            subsection: 'notification',
            to: '/notifications/notification/notificationlist',
            tooltip: 'Notifications',
          },
          // {
          //   title: this.$t('notificationSideBar.support'),
          //   icon: ['fal', 'question-circle'],
          //   subsection: 'support',
          //   to: '/notifications/support/helpalert',
          //   tooltip: 'Need help ?',
          // },
        ],
        home: [
          {
            title: this.$t('homeSideBar.home'),
            icon: ['fal', 'home'],
            subsection: 'home',
            to: '/home/home/homePage',
            tooltip: 'Home',
          },
          {
            title: this.$t('homeSideBar.settings'),
            icon: ['fal', 'user'],
            subsection: 'settings',
            to: '/home/settings/settings',
            tooltip: 'My settings',
          },
          {
            title: this.$t('homeSideBar.support'),
            icon: ['fal', 'question-circle'],
            subsection: 'support',
            to: '/home/support/helphome',
            tooltip: 'Support',
          },
          {
            title: this.$t('homeSideBar.logout'),
            icon: ['fal', 'sign-out'],
            subsection: 'signout',
            to: '/home/logout/logout',
            tooltip: 'Log Out',
          },
        ],
      };
    };
  },
};
