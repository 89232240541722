import DefaultLayout from '../layouts/Default.vue';
import FilterLayout from '../layouts/Filter.vue';
import CenteredLayout from '../layouts/Center.vue';
import AuthLayout from '../layouts/Auth.vue';
import VisitorLayout from '../layouts/Visitor.vue';

// HOME
const Home = () =>
  import(/* webpackChunkName: "pages" */ '../views/Home/Home.vue');
const SettingsPage = () =>
  import(/* webpackChunkName: "pages" */ '../views/Home/Settings.vue');
const HelpHome = () =>
  import(/* webpackChunkName: "pages" */ '../views/Home/HelpHome.vue');
const Logout = () =>
  import(/* webpackChunkName: "pages" */ '../views/Home/Logout.vue');

// AUTH
const Login = () =>
  import(/* webpackChunkName: "pages" */ '../views/Auth/Login.vue');
const PasswordInit = () =>
  import(/* webpackChunkName: "pages" */ '../views/Auth/PasswordInit.vue');
const PasswordSetup = () =>
  import(/* webpackChunkName: "pages" */ '../views/Auth/PasswordSetup.vue');

// MAINTENANCE
const Maintenance = () =>
  import(
    /* webpackChunkName: "pages" */ '../views/Maintenance/Maintenance.vue'
  );

// SCAN
const Scanner = () =>
  import(/* webpackChunkName: "pages" */ '../views/Scan/Scanner.vue');

// SEARCH
const SearchFiltersMobile = () =>
  import(/* webpackChunkName: "pages" */ '../components/SideBarFilter.vue');

// EXPLORE
const Explore = () =>
  import(/* webpackChunkName: "pages" */ '../views/Deficiencies/Explore.vue');
// STATS
const Stats = () =>
  import(/* webpackChunkName: "pages" */ '../views/Stats/Stats.vue');

// CREATE
const Create = () =>
  import(/* webpackChunkName: "pages" */ '../views/Deficiencies/Create.vue');

// ADMIN
const ProjectList = () =>
  import(/* webpackChunkName: "admin" */ '../views/Admin/ProjectList.vue');
const EntityList = () =>
  import(/* webpackChunkName: "admin" */ '../views/Admin/EntityList.vue');
const EntityCreateEdit = () =>
  import(/* webpackChunkName: "admin" */ '../views/Admin/EntityCreateEdit.vue');
const EntityManage = () =>
  import(/* webpackChunkName: "admin" */ '../views/Admin/EntityManage.vue');
const UserList = () =>
  import(/* webpackChunkName: "admin" */ '../views/Admin/UserList.vue');
const UserCreateEdit = () =>
  import(/* webpackChunkName: "admin" */ '../views/Admin/UserCreateEdit.vue');
const UserManage = () =>
  import(/* webpackChunkName: "admin" */ '../views/Admin/UserManage.vue');
const BuildingConfig = () =>
  import(
    /* webpackChunkName: "settings" */ '../views/Admin/BuildingConfig.vue'
  );
const CategoryConfig = () =>
  import(
    /* webpackChunkName: "settings" */ '../views/Admin/CategoryConfig.vue'
  );
const DefinitionConfig = () =>
  import(
    /* webpackChunkName: "settings" */ '../views/Admin/DefinitionConfig.vue'
  );
const ChecklistConfig = () =>
  import(
    /* webpackChunkName: "settings" */ '../views/Admin/ChecklistConfig.vue'
  );
const FlagConfig = () =>
  import(/* webpackChunkName: "settings" */ '../views/Admin/FlagConfig.vue');
const FlagCreateEdit = () =>
  import(/* webpackChunkName: "admin" */ '../views/Admin/FlagCreateEdit.vue');
const RequestConfig = () =>
  import(/* webpackChunkName: "settings" */ '../views/Admin/RequestConfig.vue');
const DueDateConfig = () =>
  import(/* webpackChunkName: "settings" */ '../views/Admin/DueDateConfig.vue');
const MlConfig = () =>
  import(/* webpackChunkName: "settings" */ '../views/Admin/MlConfig.vue');
const HelpAdmin = () =>
  import(/* webpackChunkName: "admin" */ '../views/Admin/HelpAdmin.vue');

// NOTIFICATIONS
const NotificationList = () =>
  import(
    /* webpackChunkName: "notifications" */ '../views/Notifications/NotificationList.vue'
  );

// STATS
// const GlobalStats = () =>
//   import(/* webpackChunkName: "stats" */ '../views/Stats/GlobalStats.vue');
// const AdvancedStats = () =>
//   import(/* webpackChunkName: "stats" */ '../views/Stats/AdvancedStats.vue');
// const UsersStats = () =>
//   import(/* webpackChunkName: "stats" */ '../views/Stats/UsersStats.vue');

// VISITOR
const Download = () =>
  import(/* webpackChunkName: "stats" */ '../views/Visitor/Download.vue');

// SECTION: HOME
const homeViews = {
  path: '/home',
  redirect: '/home/home/homePage',
  component: DefaultLayout,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: 'home/homePage',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'settings/settings',
      name: 'settings',
      component: SettingsPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'support/helphome',
      name: 'helphome',
      component: HelpHome,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'logout/logout',
      name: 'logout',
      component: Logout,
    },
  ],
};

// SECTION: AUTH
const authViews = {
  path: '/',
  redirect: '/auth/login',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/auth/login',
      name: 'Login',
      component: Login,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/auth/passwordinit',
      name: 'PasswordInit',
      component: PasswordInit,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/auth/passwordsetup',
      name: 'PasswordSetup',
      component: PasswordSetup,
      meta: {
        requiresAuth: false,
      },
    },
  ],
};

// SECTION: MAINTENANCE
const maintenanceViews = {
  path: '/maintenance',
  name: 'Maintenance',
  component: Maintenance,
  meta: {
    requiresAuth: false,
  },
};

// SECTION: scan
const scannerViews = {
  path: '/scan',
  redirect: '/scan',
  component: CenteredLayout,
  name: 'Scan',
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: '/scan',
      name: 'Scanner',
      components: { default: Scanner },
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

// SECTION: Search
const searchViews = {
  path: '/search',
  redirect: '/search',
  component: CenteredLayout,
  name: 'Search',
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: '/search/mobile',
      name: 'searchFiltersMobile',
      components: { default: SearchFiltersMobile },
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

// SECTION: Explore
const exploreViews = {
  path: '/explore',
  redirect: '/explore',
  component: FilterLayout,
  name: 'Explore',
  children: [
    {
      path: '/explore',
      name: 'explore',
      components: { default: Explore },
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

// SECTION: Stats
const statsViews = {
  path: '/stats',
  redirect: '/stats',
  component: FilterLayout,
  name: 'Stats',
  children: [
    {
      path: '/stats',
      name: 'stats',
      components: { default: Stats },
      meta: {
        requiresAuth: true,
      },
    },
    // {
    //   path: 'advanced/advancedStats',
    //   name: 'Advanced stats',
    //   components: { default: AdvancedStats },
    // },
    // {
    //   path: 'users/usersStats',
    //   name: 'Users stats',
    //   components: { default: UsersStats },
    // },
  ],
};

// SECTION: Create
const createViews = {
  path: '/create',
  redirect: '/create',
  component: CenteredLayout,
  name: 'Create',
  children: [
    {
      path: '/create/:purpose/:mode',
      name: 'create',
      components: { default: Create },
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

// SECTION: ADMIN
const adminViews = {
  path: '/admin',
  redirect: '/admin/entity/entitylist',
  component: DefaultLayout,
  name: 'Entity List',
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: 'project/projectlist',
      name: 'projectlist',
      components: { default: ProjectList },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'entity/entitylist',
      name: 'entitylist',
      components: { default: EntityList },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'entity/entitycreateedit/:uuid',
      name: 'entitycreateedit',
      components: { default: EntityCreateEdit },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'entity/entitymanage/:uuid',
      name: 'entitymanage',
      components: { default: EntityManage },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'user/userlist',
      name: 'userlist',
      components: { default: UserList },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'user/usercreateedit/:uuid',
      name: 'usercreateedit',
      components: { default: UserCreateEdit },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'user/usermanage/:uuid',
      name: 'usermanage',
      components: { default: UserManage },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'building/buildingconfig',
      name: 'buildingconfig',
      components: { default: BuildingConfig },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'category/categoryconfig',
      name: 'categoryconfig',
      components: { default: CategoryConfig },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'definition/definitionconfig',
      name: 'definitionconfig',
      components: { default: DefinitionConfig },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'checklist/checklistconfig',
      name: 'checklistconfig',
      components: { default: ChecklistConfig },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'flag/flagconfig',
      name: 'flagconfig',
      components: { default: FlagConfig },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'flag/flagcreateedit/:flagId',
      name: 'flagcreateedit',
      components: { default: FlagCreateEdit },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'request/requestconfig',
      name: 'requestconfig',
      components: { default: RequestConfig },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'duedate/duedateconfig',
      name: 'duedateconfig',
      components: { default: DueDateConfig },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'ml/mlconfig',
      name: 'mlconfig',
      components: { default: MlConfig },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'support/helpadmin',
      name: 'helpadmin',
      components: { default: HelpAdmin },
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

// SECTION: Notifications
const notificationViews = {
  path: '/notifications',
  redirect: '/notifications/notification/notificationlist',
  component: DefaultLayout,
  name: 'Notifications',
  children: [
    {
      path: 'notification/notificationlist',
      name: 'notificationlist',
      components: { default: NotificationList },
    },
  ],
};

// SECTION: Visitor
const visitorViews = {
  path: '/visitor',
  redirect: '/visitor/download',
  component: VisitorLayout,
  name: 'Visitor',
  children: [
    {
      path: '/visitor/download',
      name: 'download',
      components: { default: Download },
      // meta: {
      //   requiresAuth: false,
      // },
    },
  ],
};

const routes = [
  {
    path: '*',
    redirect: '/auth/login',
    name: 'NotFound',
  },
  homeViews,
  scannerViews,
  authViews,
  maintenanceViews,
  searchViews,
  adminViews,
  statsViews,
  notificationViews,
  exploreViews,
  createViews,
  visitorViews,
  // { path: '*', component: NotFound }
];

export default routes;
