import Vue from 'vue'; // Make sure Vue is imported

const state = {
  commentsCount: {}, // This object will store comment counts keyed by reportUuid
};

const getters = {
  /**
   * Get the comment count for a specific report.
   * @param {Object} state - The current state of the store.
   * @returns {Function} A function that takes a reportUuid and returns the comment count.
   */
  getCommentCount: (state) => (reportUuid) => {
    const count = state.commentsCount[reportUuid] || 0;
    // return state.commentsCount[reportUuid] || 0; // Return 0 if no entry exists for the reportUuid
    return count;
  },
};

const mutations = {
  /**
   * Set the comment count for a specific report.
   * @param {Object} state - The current state of the store.
   * @param {Object} payload - The payload containing the reportUuid and the new count.
   */
  setCommentCount(state, { reportUuid, count }) {
    Vue.set(state.commentsCount, reportUuid, count); // Ensures reactivity
  },
  incrementCommentCount(state, reportUuid) {
    if (state.commentsCount[reportUuid]) {
      state.commentsCount[reportUuid]++;
    } else {
      Vue.set(state.commentsCount, reportUuid, 1); // Initialize if not existing
    }
  },
  decrementCommentCount(state, reportUuid) {
    if (
      state.commentsCount[reportUuid] &&
      state.commentsCount[reportUuid] > 0
    ) {
      state.commentsCount[reportUuid]--;
    }
  },
};

const actions = {
  /**
   * Update the comment count in the store.
   * This could be expanded to include API calls if counts are to be fetched or verified remotely.
   * @param {Function} commit - Method to call mutations.
   * @param {Object} payload - Contains reportUuid and the new count.
   */
  updateCommentCount({ commit }, { reportUuid, count }) {
    commit('setCommentCount', { reportUuid, count });
  },
  incrementCommentCount({ commit }, reportUuid) {
    commit('incrementCommentCount', reportUuid);
  },
  decrementCommentCount({ commit }, reportUuid) {
    commit('decrementCommentCount', reportUuid);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
