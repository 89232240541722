// store/modules/wi.js
const state = {
  wis: {}, // An object to store WI information per report
};

const getters = {
  getWI: (state) => (reportUuid) => state.wis[reportUuid] || {},
};

const mutations = {
  SET_WI(state, { reportUuid, wi }) {
    state.wis = { ...state.wis, [reportUuid]: wi };
  },
};

const actions = {
  setWI({ commit }, payload) {
    commit('SET_WI', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
