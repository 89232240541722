<template>
  <div>

    <div class="wrapper wrapper-full-page">
      <loading-panel></loading-panel>
      <div class="full-page">
        <zoom-center-transition
          :duration="200"
          mode="out-in"
        >
          <div
            class="content"
            v-show="show"
          >

            <div class="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 ml-auto mr-auto mt-5">
              <div class="row">
                <div class="col-8 mx-auto">
                  <img
                    :src="require('@/assets/img/logo/logo_goso.png')"
                    alt="Logo Goso"
                    class="img-fluid mb-5"
                  />
                </div>
              </div>

              <router-view></router-view>

            </div>
          </div>
        </zoom-center-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { ZoomCenterTransition } from 'vue2-transitions';

export default {
  components: {
    ZoomCenterTransition,
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.show = !this.show;
  },
};
</script>
