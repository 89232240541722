<template>
  <div
    class="loader text-center"
    v-if="loaderStatus"
  >
    <font-awesome-icon
      :icon="
            ['far', 'spinner'
            ]"
      class="fa-4x"
      spin
    />

  </div>
</template>

<script>
export default {
  name: 'loading-panel',
  data() {
    return {
      loading: true,
    };
  },
  // mounted() {
  //   this.loading = this.$store.getters.loader;
  // },
  computed: {
    loaderStatus() {
      return this.$store.getters.loader;
    },
  },
};
</script>

<style scoped>
.loader {
  z-index: 10000;
  height: 90px; /* 60 */
  width: 90px; /* 60 */
  padding: 2px 10px 0px 10px; /* 15px 10px 0px 10px */
  margin: auto;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -30px; /* -50 */
  margin-left: -50px; /* -90 */
  color: white; align-content: center;
}
</style>
