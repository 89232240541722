import axiosResource from '@/utils/axiosResource';

const state = {
  reports: [],
  reportUuid: '',
  reportSequenceNumber: '',
  reportRef: '',
  selectedUuids: [],
  selectedReports: [],
  selectedReportsData: [],
  tableLoading: true,
  qtt: 0,
  qttLoaded: 0,
  page: 0,
  limit: 40,
  wis: {},
};

const getters = {
  reports: (state) => {
    return state.reports;
  },
  reportUuid: (state) => {
    return state.reportUuid;
  },
  reportSequenceNumber: (state) => {
    return state.reportSequenceNumber;
  },
  reportRef: (state) => {
    return state.reportRef;
  },
  selectedUuids: (state) => {
    return state.selectedUuids;
  },
  selectedReports: (state) => {
    return state.selectedReports;
  },
  selectedReportsData: (state) => {
    return state.selectedReportsData;
  },
  allUuids: (state) => {
    return state.allUuids;
  },
  tableLoading: (state) => {
    return state.tableLoading;
  },
  qtt: (state) => {
    return state.qtt;
  },
  qttLoaded: (state) => {
    return state.qttLoaded;
  },
  page: (state) => {
    return state.page;
  },
  limit: (state) => {
    return state.limit;
  },
  getWI: (state) => (reportUuid) => state.wis[reportUuid] || {},
};

const mutations = {
  toggleLoader: (state) => {
    state.tableLoading = !state.tableLoading;
  },
  setReports: (state, reports) => {
    // Merge the second array into the first one
    state.reports.push(...reports);
    state.tableLoading = false;
  },
  setReportUuid: (state, uuid) => {
    state.reportUuid = uuid;
  },
  setReportRef: (state, reportRef) => {
    state.reportRef = reportRef;
  },
  setAllUuids: (state, allUuids) => {
    state.allUuids = allUuids;
  },
  setSelection: (state, selection) => {
    state.selectedReports = selection;
  },
  addToSelection: (state, selection) => {
    selection.forEach((uuid) => {
      if (!state.selectedReports.includes(uuid)) {
        state.selectedReports.push(uuid);
      }
    });
  },
  deleteSelection: (state) => {
    state.selectedReports = []; // This should also be reactive
  },
  // addToSelection: (state, selection) => {
  //   selection.forEach((uuid) => {
  //     // state.selectedReports.push(uuid);
  //     if (!state.selectedReports.includes(uuid)) {
  //       state.selectedReports.push(uuid);
  //     }
  //   });
  // },
  // selectReport_OBS: (state, reportUuid) => {
  //   state.selectedReports.push(reportUuid);
  // },
  // selectReportData_OBS: (state, report) => {
  //   state.selectedReportsData.push(report);
  // },
  // unselectReport_OBS: (state, reportUuid) => {
  //   state.selectedReports = state.selectedReports.filter(function (uuid) {
  //     return uuid !== reportUuid;
  //   });
  // },
  // unselectReportData_OBS: (state, reportUuid) => {
  //   // state.selectedReportsData = [];
  //   state.selectedReportsData = state.selectedReportsData.filter(function (
  //     report
  //   ) {
  //     return report.uuid !== reportUuid;
  //   });
  // },
  selectReport(state, reportUuid) {
    if (!state.selectedReports.includes(reportUuid)) {
      state.selectedReports.push(reportUuid);
    }
  },
  selectReportData(state, report) {
    if (!state.selectedReportsData.find((r) => r.uuid === report.uuid)) {
      state.selectedReportsData.push(report);
    }
  },
  unselectReport(state, reportUuid) {
    state.selectedReports = state.selectedReports.filter(
      (uuid) => uuid !== reportUuid
    );
  },
  unselectReportData(state, reportUuid) {
    state.selectedReportsData = state.selectedReportsData.filter(
      (report) => report.uuid !== reportUuid
    );
  },
  deleteSelection: (state) => {
    state.selectedReports = [];
    state.selectedReportsData = [];
  },
  setQtt: (state, qtt) => {
    state.qtt = qtt;
    console.log(`Setting qtt to : ${qtt}`);
  },
  incrementQttLoaded: (state) => {
    state.qttLoaded += state.limit;
  },
  resetQttLoaded: (state) => {
    state.qttLoaded = state.limit;
  },
  initPage: (state) => {
    state.page = 0;
  },
  initReports: (state) => {
    state.reports = [];
  },
  incrementPage: (state) => {
    state.page++;
  },
  // SET_WI(state, { reportUuid, wi }) {
  //   const clgStyle = 'color: blue;';
  //   console.log('%c' + 'setWI', clgStyle);
  //   console.log(reportUuid, wi);
  //   state.wis = { ...state.wis, [reportUuid]: wi };
  // },
  SET_WI(state, { reportUuid, wi }) {
    // Update wis object
    state.wis = { ...state.wis, [reportUuid]: wi };

    // Update the specific report in the reports array
    const reportIndex = state.reports.findIndex(
      (report) => report.uuid === reportUuid
    );
    if (reportIndex !== -1) {
      state.reports[reportIndex] = {
        ...state.reports[reportIndex],
        wiRef: wi.wiRef,
        wiDueDate: wi.wiDueDate,
        wiPriority: wi.wiPriority,
      };
    }
  },
};

const actions = {
  changeStatus({ rootGetters }, status) {
    rootGetters.reports.forEach(function (part) {
      if (rootGetters.selectedReports.includes(part.uuid)) {
        part.status = status;
      }
    });
    // commit('deleteSelection');
  },
  setSelection({ commit }, uuids) {
    commit('setSelection', uuids);
  },
  async fetchReports({ commit, rootGetters }) {
    commit('loading', true);
    // VARIABLES
    const projectRef = rootGetters.currentProject.ref;
    const projectUuid = rootGetters.currentProject.uuid;
    const offset = rootGetters.page * rootGetters.limit;
    const clgStyle1 = 'color: magenta; font-size:20px; font-weight: 600;';
    console.log('%c' + 'fetchReports', clgStyle1);
    console.log(
      `offset= ${offset}, = page(${rootGetters.page}*limit(${rootGetters.limit}))`
    );
    console.log('freeTag before sending request:', rootGetters.freeTag);

    // AXIOS REQUEST
    const response = await axiosResource.post(
      `/reports/fetch/${projectUuid}?limit=${rootGetters.limit}&offset=${offset}`,
      {
        data: {
          projectRef,
          reportSequenceNumber: rootGetters.reportSequenceNumber,
          reportRef: rootGetters.reportRef,
          buildingName: rootGetters.building.label,
          level: rootGetters.level,
          zone: rootGetters.zone,
          serial: rootGetters.serial,
          definition: rootGetters.definition.label,
          location: rootGetters.location,
          priority: rootGetters.priority,
          quick: rootGetters.quick,
          status: rootGetters.status,
          program: rootGetters.program,
          exactMatch: rootGetters.exactMatch,
          sorting: rootGetters.sorting,
          freeTag: rootGetters.freeTag,
          dateStart: rootGetters.dateStart,
          dateEnd: rootGetters.dateEnd,
          entity: rootGetters.entity,
          inspector: rootGetters.inspector,
          nature: rootGetters.nature,
          interventionType: rootGetters.interventionType,
          withWI: rootGetters.withWI,
        },
      }
    );
    let clgStyle = 'color: red; font-size: 20px;';
    console.log('%c' + 'SENT:: rootGetters.program', clgStyle);
    console.log(rootGetters.program);
    console.log('%c' + 'SENT:: rootGetters.freeTag', clgStyle);
    console.log(rootGetters.freeTag);
    console.log('%c' + 'REPORTS BATCH LOADED =', clgStyle);
    console.log(response.data.result);

    // Feed store with commentsQtt
    response.data.result.forEach((report) => {
      commit('setCommentCount', {
        reportUuid: report.uuid,
        count: report.commentsQtt,
      });
      // Set WI in store
      const savedWI = {
        wiRef: report.wiRef,
        wiDueDate: report.wiDueDate,
        wiPriority: report.wiPriority,
      };
      commit('SET_WI', {
        reportUuid: report.uuid,
        wi: savedWI,
      });
    });
    // COMMITS TO POPULATE STORE
    commit('setQtt', response.data.total);
    commit('setReports', response.data.result);
    commit('toggleLoader');
    commit('setAllUuids', response.data.allUuids);
    commit('setSelectionMode', false);

    console.log('rootGetters.reports = ');
    console.log(rootGetters.reports);
    commit('loading', false);

    if (rootGetters.currentCard == 'map') {
      commit('setFilterChanged');
    }
  },
  setWI({ commit }, payload) {
    commit('SET_WI', payload);
  },
};

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
