import './fontawsome';
// import globalSwal from './globalSwal';
import loadingPanel from '@/components/LoadingPanel.vue';
import elementUi from './element-ui';
import { ZoomCenterTransition, CollapseTransition, SlideYUpTransition } from 'vue2-transitions';



export default {
  install(Vue) {
    // Vue.use(globalSwal);
    Vue.component(loadingPanel.name, loadingPanel);
    Vue.component(ZoomCenterTransition.name, ZoomCenterTransition);
    Vue.component(CollapseTransition.name, CollapseTransition);
    Vue.component(SlideYUpTransition.name, SlideYUpTransition);
    Vue.use(elementUi);
  },
};
