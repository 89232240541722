<template>
  <div>
    <nav-bar />
    <b-container
      id="wrapper-menu"
      class="wrapper-menu"
      fluid
    >
      <loading-panel></loading-panel>

      <router-view />

    </b-container>
    <!-- <footer-bar /> -->

  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue';
// import FooterBar from '../components/FooterBar.vue';

export default {
  name: 'default',
  components: {
    NavBar,
    // FooterBar,
  },
};
</script>