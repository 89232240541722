import {
  Form,
  Switch,
  Alert,
  Autocomplete,
  Avatar,
  Badge,
  Button,
  ButtonGroup,
  Checkbox,
  Col,
  Collapse,
  CollapseItem,
  DatePicker,
  Dialog,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Loading,
  Message,
  Popover,
  Progress,
  RadioButton,
  RadioGroup,
  Tooltip,
  Table,
  TableColumn,
  Tabs,
  TabPane,
  Tree,
  Input,
  Pagination,
  Row,
  Select,
  Skeleton,
  SkeletonItem,
  Option,
  Tag,
  Upload,
} from 'element-ui';

const elementUi = {
  install(Vue) {
    Vue.use(Form);
    Vue.use(Switch);
    Vue.use(Alert);
    Vue.use(Autocomplete);
    Vue.use(Avatar);
    Vue.use(Badge);
    Vue.use(Button);
    Vue.use(ButtonGroup);
    Vue.use(Checkbox);
    Vue.use(Col);
    Vue.use(Collapse);
    Vue.use(CollapseItem);
    Vue.use(DatePicker);
    Vue.use(Dialog);
    Vue.use(Dropdown);
    Vue.use(DropdownItem);
    Vue.use(DropdownMenu);
    Vue.use(Input);
    Vue.use(Loading);
    Vue.use(Option);
    Vue.use(Pagination);
    Vue.use(RadioButton);
    Vue.use(RadioGroup);
    Vue.use(Popover);
    Vue.use(Progress);
    Vue.use(Row);
    Vue.use(Select);
    Vue.use(Skeleton);
    Vue.use(SkeletonItem);
    Vue.use(Table);
    Vue.use(TableColumn);
    Vue.use(Tabs);
    Vue.use(TabPane);
    Vue.use(Tag);
    Vue.use(Tooltip);
    Vue.use(Tree);
    Vue.use(Upload);
    Vue.prototype.$message = Message;
  },
};

export default elementUi;
