const state = {
  exactMatch: true,
  filterChanged: false,
  building: {
    uuid: null,
    label: null,
  },
  level: '',
  serial: '',
  zone: '',
  definition: {
    value: null,
    label: null,
  },
  location: [],
  priority: {
    LONGTERM: false,
    MEDIUMTERM: false,
    SHORTTERM: false,
    URGENT: false,
  },
  quick: {
    likeActive: false,
    likeInactive: false,
    visitedActive: false,
    visitedInactive: false,
    lockedActive: false,
    lockedInactive: false,
    busyActive: false,
    busyInactive: false,
  },
  status: {
    OPEN: false,
    ADDRESSED: false,
    ONGOING: false,
    SOLVED: false,
    CLOSED: false,
    ONHOLD: false,
    NOACTION: false,
    DELETED: false,
  },
  program: {
    family: [],
    activity: [],
    specialty: [],
    remark: [],
  },
  programDefaultCheckedKeys: [],
  sorting: {
    column: '',
    order: '',
  },
  freeTag: [],
  dateStart: '',
  dateEnd: '',
  nature: [],
  inspector: [],
  entity: [],
  interventionType: [],
  wi: [],
  withWI: false,
};

const getters = {
  exactMatch: (state) => state.exactMatch,
  interventionType: (state) => {
    return state.interventionType;
  },
  entity: (state) => {
    return state.entity;
  },
  inspector: (state) => {
    return state.inspector;
  },
  nature: (state) => {
    return state.nature;
  },
  dateStart: (state) => {
    return state.dateStart;
  },
  dateEnd: (state) => {
    return state.dateEnd;
  },
  withWI: (state) => {
    return state.withWI;
  },
  freeTag: (state) => {
    return state.freeTag;
  },
  freeSearchOptions: (state) => {
    return state.freeSearchOptions;
  },
  sorting: (state) => {
    return state.sorting;
  },
  filterChanged: (state) => {
    return state.filterChanged;
  },
  building: (state) => {
    return state.building;
  },
  level: (state) => {
    return state.level;
  },
  zone: (state) => {
    return state.zone;
  },
  serial: (state) => {
    return state.serial;
  },
  definition: (state) => {
    return state.definition;
  },
  location: (state) => {
    return state.location;
  },
  priority: (state) => {
    return state.priority;
  },
  quick: (state) => {
    return state.quick;
  },
  status: (state) => {
    return state.status;
  },
  program: (state) => {
    return state.program;
  },
  programDefaultCheckedKeys: (state) => {
    return state.programDefaultCheckedKeys;
  },
};

const mutations = {
  // Mutation to update the exactMatch state
  SET_EXACT_MATCH(state, value) {
    state.exactMatch = value;
  },
  RESET_EXACT_MATCH(state) {
    state.exactMatch = true;
    console.log(`RESET_EXACT_MATCH => ${state.exactMatch}`);
  },
  setInterventionType(state, arr) {
    state.interventionType = arr;
  },
  setEntity(state, arr) {
    state.entity = arr;
    console.log(arr);
  },
  setInspector(state, arr) {
    state.inspector = arr;
  },
  setNature(state, arr) {
    state.nature = arr;
  },
  setDateStart(state, value) {
    state.dateStart = value;
  },
  setDateEnd(state, value) {
    state.dateEnd = value;
  },
  setWithWI(state, value) {
    state.withWI = value;
  },
  clearFreeTag(state) {
    const clgStyle = 'color: blue; font-size: 30px;';
    console.log('%c' + `Clearing freeTag: ${state.freeTag}`, clgStyle);
    state.freeTag = [];
  },
  removeFreeTag(state, payload) {
    const clgStyle = 'color: blue; font-size: 30px;';
    console.log('%c' + `Removing freeTag: ${payload}`, clgStyle);
    state.freeTag.splice(state.freeTag.indexOf(payload), 1);
  },
  addFreeTag(state, payload) {
    const clgStyle = 'color: blue; font-size: 30px;';
    console.log('%c' + `Adding freeTag: ${payload}`, clgStyle);
    state.freeTag.push(payload);
  },
  setFreeTag(state, payload) {
    const clgStyle = 'color: blue; font-size: 30px;';
    console.log('%c' + `Setting freeTag: ${payload}`, clgStyle);
    state.freeTag = payload;
  },
  setSorting(state, payload) {
    state.sorting.column = payload.column;
    state.sorting.order = payload.order;
  },
  initSorting(state) {
    state.sorting.column = 'id';
    state.sorting.order = 'DESC';
  },
  setFilterChanged(state) {
    state.filterChanged = !state.filterChanged;
  },
  setBuilding(state, payload) {
    state.building.uuid = payload.uuid;
    state.building.label = payload.label;
  },
  setLevel(state, val) {
    state.level = val;
  },
  setZone(state, val) {
    state.zone = val;
  },
  setSerial(state, val) {
    state.serial = val;
  },
  setDefinition(state, payload) {
    state.definition.value = payload.value;
    state.definition.label = payload.label;
  },
  setLocation(state, payload) {
    state.location = payload;
  },
  togglePriority(state, payload) {
    state.priority[payload] = !state.priority[payload];
  },
  toggleQuick(state, payload) {
    state.quick[payload] = !state.quick[payload];
  },
  toggleStatus(state, payload) {
    state.status[payload] = !state.status[payload];
  },
  setPriority(state, payload) {
    state.priority[payload] = true;
  },
  addProgram(state, payload) {
    const clgStyle = 'color: green; font-size:20px;';
    console.log('%c' + 'addProgram', clgStyle);
    console.log(`addProgram: payload: ${JSON.stringify(payload)}`);
    console.log(payload);
    switch (payload.rubric) {
      case 1:
        state.program.family.push(payload.value);
        break;
      case 2:
        state.program.activity.push(payload.value);
        break;
      case 3:
        state.program.specialty.push(payload.value);
        break;
      case 4:
        state.program.remark.push(payload.value);
        break;
      default:
        break;
    }
  },
  removeProgram(state, payload) {
    const clgStyle = 'color: orange; font-size: 20px;';
    console.log('%c' + 'removeProgram', clgStyle);
    console.log(payload);
    // If `value` directly is the identifier to remove, then no need to split.
    // If the format includes a UUID, uncomment the following line:
    // const uuidToRemove = payload.value.split('_')[1]; // Use if `value` format is "somePrefix_UUID"

    switch (payload.rubric) {
      case 1:
        state.program.family = state.program.family.filter(
          (item) => item !== payload.value
        );
        break;
      case 2:
        state.program.activity = state.program.activity.filter(
          (item) => item !== payload.value
        );
        break;
      case 3:
        state.program.specialty = state.program.specialty.filter(
          (item) => item !== payload.value
        );
        break;
      case 4:
        state.program.remark = state.program.remark.filter(
          (item) => item !== payload.value
        );
        break;
      default:
        break;
    }
  },

  // removeProgram(state, payload) {
  //   console.log('%c' + 'removeProgram', clgStyle);
  //   console.log(`removeProgram: payload: ${JSON.stringify(payload)}`);
  //   switch (payload.rubric) {
  //     case 1:
  //       state.program.family = state.program.family.filter(
  //         (item) => item !== payload.value
  //       );
  //       break;
  //     case 2:
  //       state.program.activity = state.program.activity.filter(
  //         (item) => item !== payload.value
  //       );
  //       break;
  //     case 3:
  //       state.program.specialty = state.program.specialty.filter(
  //         (item) => item !== payload.value
  //       );
  //       break;
  //     case 4:
  //       state.program.remark = state.program.remark.filter(
  //         (item) => item !== payload.value
  //       );
  //       break;
  //     default:
  //       break;
  //   }
  // },
  resetProgramChecked(state) {
    state.programDefaultCheckedKeys = [];
  },
  setProgramChecked(state, el) {
    const clgStyle = 'color: red;';
    console.log('%c' + 'setProgramChecked: el', clgStyle);
    console.log(el);
    state.programDefaultCheckedKeys.push(el);
    console.log(state.programDefaultCheckedKeys);
  },
  removeProgramChecked(state, el) {
    state.programDefaultCheckedKeys = state.programDefaultCheckedKeys.filter(
      (uuid) => {
        return uuid !== el;
      }
    );
  },
};
const actions = {
  resetExactMatch({ commit }) {
    commit('RESET_EXACT_MATCH');
    const clgStyle = 'color: red;';
    console.log('%c' + 'RESET_EXACT_MATCH', clgStyle);
  },
  async clearResult({ state, dispatch, commit }) {
    let objPriority = state.priority;
    Object.keys(objPriority).forEach((key) => {
      objPriority[key] = false;
    });
    let objQuick = state.quick;
    Object.keys(objQuick).forEach((key) => {
      objQuick[key] = false;
    });
    // commit('initPage');
    // commit('initReports');
    // commit('resetQttLoaded');
    // dispatch('fetchReports');
  },
  async clearStatus({ state, dispatch, commit }) {
    let objStatus = state.status;
    Object.keys(objStatus).forEach((key) => {
      objStatus[key] = false;
    });
    // commit('initPage');
    // commit('initReports');
    // commit('resetQttLoaded');
    // dispatch('fetchReports');
  },
  async addProgram({ commit, dispatch }, payload) {
    await commit('addProgram', payload);
    // commit('initPage');
    // commit('initReports');
    // commit('resetQttLoaded');
    // dispatch('fetchReports');
  },
  async removeProgram({ commit, dispatch }, payload) {
    await commit('removeProgram', payload);
  },
  async clearProgram({ state, dispatch, commit }) {
    state.program.family = [];
    state.program.activity = [];
    state.program.specialty = [];
    state.program.remark = [];
    // commit('initPage');
    // commit('initReports');
    // commit('resetQttLoaded');
    // dispatch('fetchReports');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
