<template>
  <div>

    <nav-bar />
    <div
      id="wrapper"
      class="wrapper-center"
    >
      <loading-panel></loading-panel>

      <router-view />

    </div>

  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue';

export default {
  name: 'default',
  components: {
    NavBar,
  },
};
</script>