const state = {
  column: {
    id: 'show',
    type: 'show',
    result: 'show',
    status: 'hide',
    building: 'hide',
    level: 'show',
    zone: 'show',
    serial: 'show',
    definition: 'show',
    program: 'show',
    description: 'show',
    comments: 'show',
    interventionDate: 'show',
    inspector: 'show',
    entity: 'show',
  },
};

const getters = {
  columnVisibility: (state) => {
    return state.column;
  },
};

const mutations = {
  toggleColumnVisibility(state, val) {
    state.column[val] == 'show'
      ? state.column[val] == 'hide'
      : state.column[val] == 'show';
  },
};

export default {
  state,
  getters,
  mutations,
};
