const state = {
  lastReport_A: {
    program: {
      madeUpId: '',
      value: '',
      family: {
        id: null,
        label: null,
      },
      activity: {
        id: null,
        label: null,
      },
      specialty: {
        id: null,
        label: null,
      },
    },
    remarks: {
      ids: [],
      value: '',
    },
    description: '',
    standard: '',
    correctiveAction: '',
    familyClass: '',
    programValue: '',
    remarksValue: '',
    descriptionValue: '',
    standardValue: '',
    correctiveActionValue: '',
  },
  lastReport_B: {
    program: {
      madeUpId: '',
      value: '',
      family: {
        id: null,
        label: null,
      },
      activity: {
        id: null,
        label: null,
      },
      specialty: {
        id: null,
        label: null,
      },
    },
    remarks: {
      ids: [],
      value: '',
    },
    description: '',
    standard: '',
    correctiveAction: '',
    familyClass: '',
    programValue: '',
    remarksValue: '',
    descriptionValue: '',
    standardValue: '',
    correctiveActionValue: '',
  },
  lastReport_C: {
    program: {
      madeUpId: '',
      value: '',
      family: {
        id: null,
        label: null,
      },
      activity: {
        id: null,
        label: null,
      },
      specialty: {
        id: null,
        label: null,
      },
    },
    remarks: {
      ids: [],
      value: '',
    },
    description: '',
    standard: '',
    correctiveAction: '',
    familyClass: '',
    programValue: '',
    remarksValue: '',
    descriptionValue: '',
    standardValue: '',
    correctiveActionValue: '',
  },
};

const getters = {
  lastReport_A: (state) => {
    return state.lastReport_A;
  },
  lastReport_B: (state) => {
    return state.lastReport_B;
  },
  lastReport_C: (state) => {
    return state.lastReport_C;
  },
};

const mutations = {
  saveLastReport(state, report) {
    state.lastReport_C = state.lastReport_B;
    state.lastReport_B = state.lastReport_A;
    state.lastReport_A = report;
  },
  resetLastReportState(state) {
    Object.assign(state, getDefaultState());
  },
};

const getDefaultState = () => {
  return {
    lastReport_A: {
      program: {
        madeUpId: '',
        value: '',
        family: {
          id: null,
          label: null,
        },
        activity: {
          id: null,
          label: null,
        },
        specialty: {
          id: null,
          label: null,
        },
      },
      remarks: {
        ids: [],
        values: [],
      },
      description: '',
      standard: '',
      correctiveAction: '',
      familyClass: '',
      programValue: '',
      remarksValue: '',
      descriptionValue: '',
      standardValue: '',
      correctiveActionValue: '',
    },
    lastReport_B: {
      program: {
        madeUpId: '',
        value: '',
        family: {
          id: null,
          label: null,
        },
        activity: {
          id: null,
          label: null,
        },
        specialty: {
          id: null,
          label: null,
        },
      },
      remarks: {
        ids: [],
        values: [],
      },
      description: '',
      standard: '',
      correctiveAction: '',
      familyClass: '',
      programValue: '',
      remarksValue: '',
      descriptionValue: '',
      standardValue: '',
      correctiveActionValue: '',
    },
    lastReport_C: {
      program: {
        madeUpId: '',
        value: '',
        family: {
          id: null,
          label: null,
        },
        activity: {
          id: null,
          label: null,
        },
        specialty: {
          id: null,
          label: null,
        },
      },
      remarks: {
        ids: [],
        values: [],
      },
      description: '',
      standard: '',
      correctiveAction: '',
      familyClass: '',
      programValue: '',
      remarksValue: '',
      descriptionValue: '',
      standardValue: '',
      correctiveActionValue: '',
    },
  };
};

export default {
  state,
  getters,
  mutations,
};
