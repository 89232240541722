// projects.js (Vuex Module)
import Vue from 'vue'; // Make sure Vue is imported
import axiosResource from '@/utils/axiosResource';

const state = {
  currentProject: [],
  projects: [],
  loading: false,
  qtt: 0,
};

const getters = {
  currentProject: (state) => state.currentProject,
  projects: (state) => state.projects,
  loading: (state) => state.loading,
  // qtt: (state) => state.qtt,
};

const mutations = {
  setCurrentProject(state, project) {
    const clgStyle = 'color: green;';
    console.log('%c' + 'setCurrentProject', clgStyle);
    console.log(project);
    state.currentProject = project;
    return;
  },
  setProjects(state, projects) {
    state.projects = projects;
  },
  setQtt(state, qtt) {
    state.qtt = qtt;
  },
  addProject(state, newProject) {
    state.projects.push(newProject);
  },
  setError(state, error) {
    this.$globalSwal.error('happens');
    console.error('Store error:', error);
  },
  updateProject(state, { uuid, projectData }) {
    const index = state.projects.findIndex((p) => p.uuid === uuid);
    if (index !== -1) {
      // Using Vue.set to ensure reactivity
      Vue.set(state.projects, index, {
        ...state.projects[index],
        ...projectData,
      });
    }
  },
};

const actions = {
  createProject({ commit }, projectData) {
    commit('loading', true);
    return axiosResource
      .post('/admin/projects/', projectData)
      .then((response) => {
        commit('addProject', response.data.project);
        commit('loading', false);
        return response.data.project;
      })
      .catch((error) => {
        commit('loading', false);
        commit('setError', error.response.data.msg);
      });
  },
  updateProject({ commit }, { uuid, projectData }) {
    commit('loading', true);
    return axiosResource
      .put(`/admin/projects/${uuid}`, projectData)
      .then((response) => {
        if (response.data.success) {
          commit('updateProject', { uuid, projectData });
          commit('loading', false);
          return response.data;
        } else {
          throw new Error('Update failed');
        }
      })
      .catch((error) => {
        console.log(error);
        commit('loading', false);
        // commit('setError', error.response.data.msg);
        throw error; // Re-throw the error for further handling
      });
  },
  fetchProjects({ commit }) {
    commit('loading', true);
    axiosResource
      .get('/admin/projects/', { withCredentials: true })
      .then((response) => {
        commit('setProjects', response.data.projects);
        commit('setQtt', response.data.projects.length);
        commit('loading', false);
      })
      .catch((error) => {
        commit('loading', false);
        commit('setError', error.response.data.msg);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
