import axios from 'axios';
import getConfig from '../config';

const host = getConfig();
// alert(`AUTH=${host.auth} RESOURCE=${host.resource}`);

const axiosAuth = axios.create({
  baseURL: `${host.auth}/api`,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  withCredentials: true,
});

export default axiosAuth;
